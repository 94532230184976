﻿@import 'media-query';

.efp-floating-button-container {
  width: inherit;
  position: fixed;
  bottom: 16px;
  display: flex;
  justify-content: center;
  z-index: unset;
  pointer-events: none;
  gap: 16px;

  .efp-floating-button {
    height: 60px;
    width: 60px;
    font-weight: bold;
    pointer-events: all;
    gap: 8px;

    &[disabled] {
      cursor: default !important;
      opacity: 1;
      pointer-events: none;
    }

    --border-radius: 100px;
    --border-style: solid;
    --border-width: 0px;
    --box-shadow: 0px 0px 12px 0px rgb(0, 69, 136, 24%), 0px 0px 24px 4px rgb(0, 69, 136, 8%) !important;

    span {
      display: none;
    }

    &.light {
      border-radius: 100px;
      border: 2px solid var(--IX_Enabled_HighContrast, #004588);

      --background: #eff0f0;
      --color: var(--ion-color-primary);

      &[disabled] {
        border: 2px solid var(--IX_Disabled_LowContrast, #c8c9cb);

        --background: #eff0f0 !important;
      }

      &:hover {
        border: 2px solid var(--IX_Hover_HighContrast, #003a73);

        --background: var(--IX_Hover_LowContrast, #ebf0f6);
      }
    }

    &.dark {
      --background: var(--ion-color-primary, #004588);
      --color: white;

      &[disabled] {
        --background: #c8c9cb !important;
      }
    }

    @media (min-width: $efp-web-breakpoint) {
      width: fit-content;
      height: 48px;

      span {
        display: block;
      }

      ion-icon {
        margin-right: 8px;
      }
    }
  }

  @media (min-width: $efp-web-breakpoint) {
    bottom: 24px;
    gap: 24px;
  }
}
