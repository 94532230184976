@import 'media-query';

.efp-circle-floating-button {
  height: 40px;
  width: 40px;
  font-weight: bold;
  pointer-events: all;

  --padding-bottom: 0;
  --padding-top: 0;
  --padding-start: 0;
  --padding-end: 0;
  --border-radius: 20px;
  --border-style: solid;
  --border-width: 0px;
  --box-shadow: 0px 0px 12px 0px rgb(0, 69, 136, 28%), 0px 0px 24px 4px rgb(0, 69, 136, 12%) !important;

  &[disabled] {
    cursor: default !important;
    opacity: 1;
    pointer-events: none;
  }

  &.light {
    --background: #eff0f0;
    --color: var(--ion-color-primary);

    &[disabled] {
      --background: #eff0f0 !important;
      --color: var(--IX_Disabled_LowContrast, #c8c9cb);
    }

    &:hover {
      --background: var(--IX_Hover_LowContrast, #003a73);
      --color: white;
    }
  }

  &.dark {
    --background: var(--ion-color-primary, #004588);
    --color: white;

    &[disabled] {
      --background: var(--IX_Disabled_LowContrast, #c8c9cb);
      --color: white;
    }
  }

  span {
    display: none;
  }
}
