@import 'media-query';
@import 'variables';

/**
 * Fullscreen modal for bigger devices
 * min-width is from: https://github.com/ionic-team/ionic-framework/blob/main/core/src/css/core.scss 
*/
@media screen and (min-width: 768px) {
  ion-modal.fullscreen {
    --width: calc(100% - 40px);
    --height: calc(100% - 40px);
    --border-radius: 10px;
  }
}

ion-modal {
  &.modal-generic {
    --width: 448px;
    --height: 240px;
  }

  &.modal-responsive-max-w-400 {
    --max-height: unset;
    --margin: 12px;
    --overflow-y: auto;
    --max-width: calc(100vw - 24px);
    --height: auto;

    @media (min-width: $efp-web-breakpoint) {
      --max-width: 400px;
    }
  }
}

.onboarding-modal {
  --border-radius: 24px;
  --height: auto;
  --background: white;

  /* mobile */
  --width: 380px;

  /* web */
  @media (min-width: $bp-lg) {
    --width: 448px;
  }

  /** styling onboarding dialog **/
  ion-title {
    --color: var(--ion-color-light-contrast, #22272c);

    font-size: 20px;
    padding: 12px;

    @media (min-width: $bp-lg) {
      padding: 24px 24px 16px;
    }
  }

  .onboarding-content {
    max-height: 650px;
  }

  ion-toolbar.header-toolbar {
    --background: var(--Surface_Level-0, #eff0f0);
  }

  ion-button.navigation-button {
    border-radius: 100px;

    --background: var(--ion-color-primary, #004588);
    --color: white;

    &.light {
      --background: white;
      --color: var(--ion-color-primary);

      border: 2px solid var(--IX_Enabled_HighContrast, #004588);
    }

    &.hidden {
      visibility: hidden;
    }

    &.dark {
      --background: var(--ion-color-primary);
      --color: white;
    }
  }

  .footer-toolbar {
    padding: 12px !important;

    --background: white;

    @media (min-width: $bp-lg) {
      padding: 24px !important;
    }
  }

  .hint-status-indicators {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    ion-icon {
      fill: var(--IX_Disabled_LowContrast, #c8c9cb);

      &.active {
        fill: var(--IX_Enabled_HighContrast, #004588);
      }
    }
  }
}
