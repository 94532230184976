.tooltip {
  position: absolute;

  & > div {
    position: relative;
    padding: 8px;
    font-size: 13px;
    border-radius: 4px;
    overflow: auto;
    background-color: var(--ion-color-light-contrast);
    color: var(--ion-color-primary-contrast);
    line-break: auto;
  }

  &-right {
    &::after {
      content: url('/assets/icon/tooltip-right.svg');
      position: absolute;
      right: 0;
      left: -6px;
      top: calc(50% - 10px);
    }
  }

  &-left {
    &::after {
      content: url('/assets/icon/tooltip-left.svg');
      position: absolute;
      right: 0;
      right: -6px;
      bottom: 6px;
    }
  }

  &-bottom {
    &::before {
      content: url('/assets/icon/tooltip-bottom.svg');
      position: absolute;
      right: calc(50% - 6px);
      top: -15px;
    }
  }

  &-top {
    &::after {
      content: url('/assets/icon/tooltip-top.svg');
      position: absolute;
      right: calc(50% - 6px);
      bottom: -10px;
    }
  }
}
