/**
 * ion-segment and ion-segment-button overrides
 */
ion-segment.md {
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
}

ion-segment-button.md {
  flex-grow: 0;
  text-transform: none;
  font-weight: bold;
  min-width: 0;

  --indicator-height: 4px;
  --padding-start: 0;
  --padding-end: 0;
}

ion-segment.ios {
  border: 1px solid var(--ion-color-primary);
  width: fit-content;
}

ion-segment-button.ios {
  --indicator-color: var(--ion-color-primary);
  --color-checked: var(--ion-color-primary-contrast);
  --margin-end: 0;
  --margin-start: 0;

  min-width: 24px;
}
