ion-button {
  &.separator[disabled] {
    opacity: 1;
    margin: 0 4px;
    width: 2px;
    background: var(--ion-color-primary-contrast);
    pointer-events: none;
  }

  &.icon-button {
    width: 48px;
    height: 48px;

    --padding-start: 0;
    --padding-end: 0;

    icon {
      flex-shrink: 0;
    }
  }
}
