@import 'media-query';

@mixin generate-flex-classes() {
  @for $i from 1 through 10 {
    .flex-#{$i} {
      flex: $i;
    }
  }
}

@include generate-flex-classes;

@mixin generate-gap-classes() {
  @for $i from 1 through 20 {
    $gap-value: $i * 4;
    .gap-#{$gap-value} {
      gap: #{$gap-value}px;
    }
  }
}

@mixin transition($properties: all, $duration: 0.4s, $timing-function: ease-in-out) {
  transition: $properties $duration $timing-function;
}

@include generate-gap-classes;

$breakpoints: (
  'web': $bp-lg,
);

@mixin generate-padding-classes() {
  $directions: ('top', 'bottom', 'right', 'left');

  @for $i from 1 through 40 {
    $padding-value: $i * 2;

    @each $dir in $directions {
      .p-#{$dir}-#{$padding-value} {
        padding-#{$dir}: #{$padding-value}px;
      }

      @media (min-width: map-get($breakpoints, 'web')) {
        .web\:p-#{$dir}-#{$padding-value} {
          padding-#{$dir}: #{$padding-value}px;
        }
      }
    }
  }
}

@include generate-padding-classes;
