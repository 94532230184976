ion-select {
  background-image: url('/assets/icon/arrow-right.svg');
  background-position: right;
  background-repeat: no-repeat;
  background-size: 12px 20px;
  padding-right: 20px;
  text-align: right;
  margin-inline-start: 0;
  font-weight: 400 !important;
}

ion-select::part(icon) {
  display: none !important;
  margin-left: 50px;
}

ion-select[disabled] {
  background-image: none;
}
