/**
 * This file contains style overrides for ion-styling to better inline them with our UI design.
 */
@import 'overrides/ion-button';
@import 'overrides/ion-item';
@import 'overrides/ion-segment';
@import 'overrides/ngx-extended-pdf-viewer';
@import 'overrides/form';
@import 'overrides/ion-select';
@import 'overrides/ion-list';
@import 'overrides/popovers';
@import 'overrides/modals';
