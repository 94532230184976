@import 'media-query';

.visibility-menu {
  --width: min-content;

  &.cycle-menu {
    --width: 300px;
    --offset-y: -15px;
  }
}

.select-popover {
  --box-shadow: 0 0 12px #00458847;

  ion-select-popover {
    border: 1px solid var(--ion-color-primary-highlight);
    border-radius: 4px;
    overflow: auto;

    ion-item:hover {
      --background: var(--efp-hover-high-contrast);

      background: var(--efp-hover-high-contrast);
    }
  }
}
