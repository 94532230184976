// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-font-family: roboto, sans-serif;
  --ion-color-primary: #004588;
  --ion-color-primary-rgb: 0, 69, 136;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-low-contrast: #dedfe0;
  --ion-color-primary-shade: #003d78;
  --ion-color-primary-tint: #1a5894;
  --ion-color-primary-highlight: #00305e;
  --ion-color-secondary: #fd0;
  --ion-color-secondary-rgb: 255, 221, 0;
  --ion-color-secondary-contrast: #000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #e0c200;
  --ion-color-secondary-tint: #ffe01a;
  --ion-color-tertiary: #8e9dc8;
  --ion-color-tertiary-rgb: 142, 157, 200;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-hover-high-contrast: #003a73;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #fff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  --ion-color-danger: #eb445a;
  --ion-color-danger-dark: #9e282e;
  --ion-color-error-light: #f3d6d7;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  --ion-color-dark: #484c50;
  --ion-color-dark-rgb: 72, 76, 80;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #000;
  --ion-color-dark-tint: #1a1a1a;
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #fff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #6a6d71;
  --ion-color-medium-tint: #9d9fa6;
  --ion-color-light: #eff0f0;
  --ion-color-light-rgb: 239, 240, 240;
  --ion-color-light-contrast: #22272c;
  --ion-color-light-contrast-rgb: 34, 39, 44;
  --ion-color-light-shade: #d2d3d3;
  --ion-color-light-tint: #f1f2f2;
  --ion-text-color: #383c40;
  --ion-background-color: #eff0f0;
  --ion-line-medium-contrast: #c8c9cb;
  --efp-border-radius: 15px;
  --efp-box-radius: 4px;
  --efp-line-low-contrast: #dedfe0;
  --efp-hover-high-contrast: #ebf0f6;
  --efp-light-blue: #d5e0eb;
  --efp-shadow: 0px 0px 12px 0px rgba(0, 69, 136, 24%), 0px 0px 24px 4px rgba(0, 69, 136, 8%);
  --efp-shadow-inset: inset 0 0 12px 0 rgba(0, 69, 136, 24%);
  --efp-shadow-inset-offset-y: inset 0 8px 10px 0 rgb(0, 69, 136, 12%);
}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */

.ios body {
  --ion-background-color: #eff0f0;
  --ion-background-color-rgb: 239, 240, 240;
  --ion-text-color: #383c40;
  --ion-text-color-rgb: 56, 60, 64;
  --ion-color-step-50: #e6e7e7;
  --ion-color-step-100: #dddede;
  --ion-color-step-150: #d4d5d6;
  --ion-color-step-200: #cacccd;
  --ion-color-step-250: #c1c3c4;
  --ion-color-step-300: #b8babb;
  --ion-color-step-350: #afb1b2;
  --ion-color-step-400: #a6a8aa;
  --ion-color-step-450: #9d9fa1;
  --ion-color-step-500: #949698;
  --ion-color-step-550: #8a8d8f;
  --ion-color-step-600: #818486;
  --ion-color-step-650: #787b7e;
  --ion-color-step-700: #6f7275;
  --ion-color-step-750: #66696c;
  --ion-color-step-800: #5d6063;
  --ion-color-step-850: #53575a;
  --ion-color-step-900: #4a4e52;
  --ion-color-step-950: #414549;
  --ion-item-background: #fff;
  --ion-card-background: #eff0f0;
}

.ios ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}

/*
 * Material Design Dark Theme
 * -------------------------------------------
 */

.md body {
  --ion-background-color: #eff0f0;
  --ion-background-color-rgb: 239, 240, 240;
  --ion-text-color: #383c40;
  --ion-text-color-rgb: 56, 60, 64;
  --ion-color-step-50: #e6e7e7;
  --ion-color-step-100: #dddede;
  --ion-color-step-150: #d4d5d6;
  --ion-color-step-200: #cacccd;
  --ion-color-step-250: #c1c3c4;
  --ion-color-step-300: #b8babb;
  --ion-color-step-350: #afb1b2;
  --ion-color-step-400: #a6a8aa;
  --ion-color-step-450: #9d9fa1;
  --ion-color-step-500: #949698;
  --ion-color-step-550: #8a8d8f;
  --ion-color-step-600: #818486;
  --ion-color-step-650: #787b7e;
  --ion-color-step-700: #6f7275;
  --ion-color-step-750: #66696c;
  --ion-color-step-800: #5d6063;
  --ion-color-step-850: #53575a;
  --ion-color-step-900: #4a4e52;
  --ion-color-step-950: #414549;
  --ion-item-background: #fff;
  --ion-card-background: #eff0f0;
}
